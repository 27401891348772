/* app/on-demand/elements/easyexpand */

/**
 * Allows an easy way to expand and collapse elements.
 * The trigger element needs the class "js-collapse-toggle".
 * All elements with that class will automatically have triggers applied.
 * The triggers will be activated on click and on focus.
 * 
 * All trigger elements need a "target" data attribute with a selector for the element it triggers.
 * (e.g. data-target="#collapsible-element")
 * 
 * All collapsible elements need a "toggler" data attribute with a selector for the element that triggers it.
 * (e.g. data-toggler="#clickable-element")
 * 
 * If you want the collapsible element to be collapsed by default, add the "collapsed" class to it.
 * Otherwise it will be expanded by default (in which case you should add the "toggle--open" class to the toggle button)
 * Obviously the styling actually determines what it means to be collapsed or expanded.
 * 
 * e.g.
 * <button id="menu-icon" class="menu-icon js-collapse-toggle" data-target="#nav-mobile" aria-expanded="false">
 * 	<span class="hidden-text">expand/collapse navigation menu</span>
 * 	<span class="menu-icon__bar menu-icon__bar--top" aria-hidden="true"></span>
 * 	<span class="menu-icon__bar menu-icon__bar--mid" aria-hidden="true"></span>
 * 	<span class="menu-icon__bar menu-icon__bar--btm" aria-hidden="true"></span>
 * </button>
 * 
 * <nav id="nav-mobile" class="nav--mobile collapsed" data-toggler="menu-icon">
 *	<!-- Nav stuff here -->
 * </nav>
 */

define(
	[
		'jquery'
	],

	function ($) {

		var EasyExpand;

		var selectors = {
			toggle: ".js-collapse-toggle"
		};

		var classes = {
			initialised: "js-expand-initialised",
			toggleOpen: "toggle--open",
			collapsed: "collapsed"
		};

		return {

			/** Initialise the easyexpand functionality.
			*
			* @method module:app/on-demand/elements/easyexpand.init
			*/
			init: function () {
				EasyExpand = this;
				this._initEvents();
			},

			_initEvents: function () {
				$(selectors.toggle).each(function () {
					var $toggle = $(this);
					if (!$toggle.hasClass(classes.initialised)) {
						// Menu toggle via button.
						$toggle.on('click', EasyExpand._clickCollapseToggle);
						// Menu toggle via focus.
						var $target = $($(this).data('target'));
						$target.on('focusin', '> *', EasyExpand._focusCollapseToggle);
						$target.on('focusout', '> *', EasyExpand._focusCollapseToggle);
						//Don't initialise again.
						$toggle.addClass(classes.initialised);
					}
				});
			},

			_clickCollapseToggle: function (event) {
				event.preventDefault();

				var $collapsible = $($(this).data('target'));
				var isCollapsed = $collapsible.hasClass(classes.collapsed);

				if (isCollapsed) {
					$collapsible.removeClass(classes.collapsed);
					$(this).addClass(classes.toggleOpen);
				} else {
					$collapsible.addClass(classes.collapsed);
					$(this).removeClass(classes.toggleOpen);
				}

				$(this).attr('aria-expanded', isCollapsed);
			},

			_focusCollapseToggle: function(event) {
				var $collapsible = $(event.delegateTarget)
				var $toggler = $($collapsible.data('toggler'));

				switch (event.type) {
					case 'focusin':
						var isCollapsed = $collapsible.hasClass(classes.collapsed);
						if (isCollapsed) {
							$collapsible.removeClass(classes.collapsed);
							$toggler.attr('aria-expanded', true);
							$toggler.addClass(classes.toggleOpen);
						}
						break;

					case 'focusout':
						var stayExpanded = $collapsible.has(event.relatedTarget).length > 0;
						if (!stayExpanded) {
							$collapsible.addClass(classes.collapsed);
							$toggler.attr('aria-expanded', false);
							$toggler.removeClass(classes.toggleOpen);
						}
						break;
				}
			}

		};
	}
);