/* app/on-demand/all */
import Vimeo from 'app/ui/video/vimeo';

import $ from 'jquery';
import LazyLoad from 'util/lazyload';
import Ajax from 'util/ajax';
import EasyExpand from 'app/on-demand/elements/easyexpand';
import SocialShare from 'app/ui/social/share';
import YouTube from 'app/ui/video/youtube';
import VideoLoad from 'app/ui/video/load';
import DatePicker from 'app/ui/filter/date-picker-accessible';

EasyExpand.init();
LazyLoad.init();
Ajax.init();
Vimeo.init();
YouTube.init();
VideoLoad.init();
SocialShare.init();
DatePicker.init();

$('.js-scroll-top').on('click', function (e) {
	e.preventDefault();
	$("html, body").animate({ scrollTop: 0 }, "slow");
});