/* app/ui/video/load */

define(
	[
		'jquery',
		'util/mediaqueries',
		'util/lazyload',
		'app/ui/video/playlist',
		'app/ui/video/common',
		'app/ui/video/youtube',
		'app/ui/video/vimeo',
		'pubsub',
	],
	function ($, MediaQueries, LazyLoad, VideoPlaylist, VideoCommon, YouTube, _Vimeo) {

		var VideoLoad;
		var Vimeo = _Vimeo.default || _Vimeo;

		return {
			init: function() {
				VideoLoad = this;

				// Run callback function after scrolling to playlist element triggers lazyScroll plugin
				// This will ensure the YouTube API and supporting files are only loaded when necessary
				// Will only run once - js hook removed once the playlist has been loaded
				LazyLoad.initLazyFunction({
					elems: $('.js-load-playlist'),
					callback: function () {
						var $thisPlaylist = $(this);
						VideoPlaylist.init($thisPlaylist);
					}
				});
				VideoLoad._initMediaQueries();
			},

			_initMediaQueries: function () {
				MediaQueries.register([
					{
						queries: MediaQueries.queries['below-768'],
						shouldDegrade: false,
						match: VideoLoad._initMobile
					},
					{
						queries: MediaQueries.queries['768-plus'],
						shouldDegrade: true,
						match: VideoLoad._initDesktop
					}
				]);
			},

			_initDesktop: function() {
				$('.js-video-player-youtube').on('click', '.js-video', VideoLoad._loadVideo);
				$('.js-video-player-vimeo').on('click', '.js-video', VideoLoad._loadVideoVimeo);
			},

			_initMobile: function() {
				$('.js-video-player-youtube').off('click', '.js-video', VideoLoad._loadVideo);
				$('.js-video-player-vimeo').off('click', '.js-video', VideoLoad._loadVideoVimeo);
			},

			_loadVideo: function( event ) {
				event.preventDefault();
				var thisVid = this;

				VideoCommon.init(thisVid);
				YouTube.initElement( thisVid );
			},

			_loadVideoVimeo: function (event) {
				event.preventDefault();
				var thisVid = this;
				Vimeo.loadVideo( thisVid );
			}
		};
	}
);